import request from '@/utils/request'


export function personnelAccountGroupQuery(data) {
    return request({
        url: '/platform_api/manage_api/account_group/query',
        method: 'post',
        data: data
    })
}


export function personnelAccountGroupDeleteGroup(data) {
    return request({
        url: '/platform_api/manage_api/account_group/delete_group',
        method: 'post',
        data: data
    })
}


export function personnelAccountGroupDeleteUpdate(data) {
    return request({
        url: '/platform_api/manage_api/account_group/update_group',
        method: 'post',
        data: data
    })
}


export function personnelAccountGroupDeleteCreate(data) {
    return request({
        url: '/platform_api/manage_api/account_group/create_group',
        method: 'post',
        data: data
    })
}