import request from '@/utils/request'


export function personnelAccount_JurisdictionGroupQuery(data) {
    return request({
        url: '/platform_api/manage_api/account_jurisdiction/query',
        method: 'post',
        data: data
    })
}


export function personnelAccount_JurisdictionGroupCreate(data) {
    return request({
        url: '/platform_api/manage_api/account_jurisdiction/create',
        method: 'post',
        data: data
    })
}


export function personnelAccount_JurisdictionGroupGet(data) {
    return request({
        url: '/platform_api/manage_api/account_jurisdiction/get',
        method: 'post',
        data: data
    })
}


export function personnelAccount_JurisdictionGroupUpdate(data) {
    return request({
        url: '/platform_api/manage_api/account_jurisdiction/update',
        method: 'post',
        data: data
    })
}


export function personnelAccount_JurisdictionGroupInitlist(data) {
    return request({
        url: '/platform_api/manage_api/account_jurisdiction/initlist',
        method: 'post',
        data: data
    })
}