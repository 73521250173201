<template>
  <div>
    <infoHeader :content_name="'账户详细信息'" />

    <h3 class="h3title-top">修改账户信息</h3>

    <el-form ref="form" style="max-width: 800px; min-width: 300px" >
      <el-form-item label="用户名">
        <el-input v-model="userdata.name"></el-input>
      </el-form-item>

        <el-row :gutter="50">

            <el-col :span="12">
            <el-form-item label="邮箱">
                <el-input v-model="userdata.email"></el-input>
            </el-form-item>
            </el-col>

            <el-col :span="12">
            <el-form-item label="手机号">
                <el-input v-model="userdata.phone"></el-input>
            </el-form-item>
            </el-col>

        </el-row>

        <!-- <el-divider></el-divider> -->

      <el-form-item label="选择账户状态">
        <el-select v-model="userdata.status" placeholder="请选择账户状态">
          <el-option
            v-for="item in status_dict"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updateuserinfo()">提交新的账户信息</el-button>
      </el-form-item>

    </el-form>

      <el-divider></el-divider>

    <h3 class="h3title">修改账户密码</h3>

    <el-form ref="form" style="max-width: 800px; min-width: 300px;" >
        <el-form-item label="登录密码">
            <el-input placeholder="请输入密码" v-model="password_data.password" show-password></el-input>
        </el-form-item>

        <el-form-item label="重复输入一遍登录密码">
            <el-input placeholder="请输入密码" v-model="password_data.re_password" show-password></el-input>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updatepassword()">提交新的密码</el-button>
      </el-form-item>

    </el-form>
    <el-divider></el-divider>

    <h3 class="h3title">修改账户用户组</h3>

    <el-form ref="form" style="max-width: 800px; min-width: 300px;" >

        <el-form-item>
          <el-select v-model="account_group_data.account_group_id">
            <el-option
              key="0"
              label="未知"
              :value="0"
            >
            </el-option>
            <el-option
              v-for="(item, index) in account_group"
              :key="index"
              :label="item.group_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updateUserGroup()">更新用户组信息</el-button> <el-button type="primary" @click="delUserGroup()">清除用户组绑定</el-button>
      </el-form-item>

    </el-form>

  <el-divider></el-divider>

    <h3 class="h3title">修改权限组</h3>

    <el-form ref="form" style="max-width: 800px; min-width: 300px;" >

        <el-form-item>
          <el-select v-model="jurisdiction_group_data.jurisdiction_group_id">
            <el-option
              key="0"
              label="未知"
              :value="0"
            >
            </el-option>

          <el-option
            v-for="(item, index) in jurisdiction_group"
            :key="index"
            :label="item.roles_name"
            :value="item.id"
          >
          </el-option>


          </el-select>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updateJurisdictionGroup()">更新关联权限组</el-button> <el-button type="primary" @click="delJurisdictionGroup()">清除权限组绑定</el-button>
      </el-form-item>

    </el-form>

  <el-divider></el-divider>

  </div>
</template>

<script>
import { personnelAccountGet, personnelAccountUpdate, personnelAccountUpdate_setAccountGroup, personnelAccountUpdate_delAccountGroupIndex, personnelAccountUpdate_setJurisdictionGroup, personnelAccountUpdate_delJurisdictionGroupIndex } from "@/api/manage/personnel_management.js"
import { personnelAccountGroupQuery } from "@/api/manage/personnel_account_group.js"
import { personnelAccount_JurisdictionGroupQuery } from "@/api/manage/personnel_account_jurisdiction.js"
export default {
  data() {
    this.id = this.$route.query.id;
    return {
        userdata: {
            id:this.id,
            name:null,
            email:null,
            phone:null,
            status:null,
        },
        password_data:{
            id:this.id,
            password: null,
            re_password: null
        },
        status_dict: [
          { value: 1, label: "正常" },
          { value: 2, label: "禁用" },
          { value: 3, label: "黑名单" },
        ],
        account_group: [],
        account_group_data: {
          account_group_id: 0,
          account_id:this.id,
        },
        jurisdiction_group: [],
        jurisdiction_group_data: {
          jurisdiction_group_id: 0,
          account_id:this.id,
        },
    };
  },
  components: {},
  created() {
    this.getUserinfo();
    this.getAccountGroupItem();
    this.getJurisdictionGroupItem();
  },
  methods: {
    getUserinfo() {
      this.$http(
        personnelAccountGet({
          account_id: this.id,
        }),
        (res) => {
            this.userdata = res.data
            this.account_group_data.account_group_id = res.data.account_group.id
            this.jurisdiction_group_data.jurisdiction_group_id =res.data.jurisdiction.id 
        }
      );
    },
    getAccountGroupItem(){
      this.$http(
        personnelAccountGroupQuery({}),
        (res) => {
            this.account_group = res.data
        }
      );
    },
    getJurisdictionGroupItem(){
      this.$http(
        personnelAccount_JurisdictionGroupQuery({}),
        (res) => {
            this.jurisdiction_group = res.data
        }
      );
    },
    updateuserinfo(){
      this.$http(
        personnelAccountUpdate(this.userdata),
        () => {
            this.getUserinfo()
        }
      );
    },
    updatepassword(){
      this.$http(
        personnelAccountUpdate(this.password_data),
        () => {
            this.getUserinfo()
        }
      );
    },
    updateJurisdictionGroup(){
      this.$http(
        personnelAccountUpdate_setJurisdictionGroup(this.jurisdiction_group_data),
        () => {
            this.getUserinfo()
        }
      );
    },
    delJurisdictionGroup(){
      this.$http(
        personnelAccountUpdate_delJurisdictionGroupIndex({
          account_id: this.id,
        }),
        () => {
            this.getUserinfo()
        }
      );
    },
    updateUserGroup(){
      this.$http(
        personnelAccountUpdate_setAccountGroup(this.account_group_data),
        () => {
            this.getUserinfo()
        }
      );
    },
    delUserGroup(){
      this.$http(
        personnelAccountUpdate_delAccountGroupIndex({
          account_id: this.id,
        }),
        () => {
            this.getUserinfo()
        }
      );
    },
  },
};
</script>

<style type="scss">
</style>