import request from '@/utils/request'


export function personnelAccountQuery(data) {
    return request({
        url: '/platform_api/manage_api/account/query',
        method: 'post',
        data: data
    })
}


export function personnelAccountCreate(data) {
    return request({
        url: '/platform_api/manage_api/account/create',
        method: 'post',
        data: data
    })
}


export function personnelAccountGet(data) {
    return request({
        url: '/platform_api/manage_api/account/get',
        method: 'post',
        data: data
    })
}


export function personnelAccountUpdate(data) {
    return request({
        url: '/platform_api/manage_api/account/update',
        method: 'post',
        data: data
    })
}


export function personnelAccountUpdate_setJurisdictionGroup(data) {
    return request({
        url: '/platform_api/manage_api/account/update/set_jurisdiction_group',
        method: 'post',
        data: data
    })
}


export function personnelAccountUpdate_delJurisdictionGroupIndex(data) {
    return request({
        url: '/platform_api/manage_api/account/update/del_jurisdiction_group_index',
        method: 'post',
        data: data
    })
}


export function personnelAccountUpdate_setAccountGroup(data) {
    return request({
        url: '/platform_api/manage_api/account/update/set_account_group',
        method: 'post',
        data: data
    })
}


export function personnelAccountUpdate_delAccountGroupIndex(data) {
    return request({
        url: '/platform_api/manage_api/account/update/del_account_group_index',
        method: 'post',
        data: data
    })
}